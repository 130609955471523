<template lang="pug">
    #page-inner.scroll-x.pb-0.height_100vh
        .upload_page_height.scrollbar.px-3()
            v-container
                v-row.mt-2.elevation-0
                    v-col(cols='2')
                        v-btn.green.elevation-0(@click='showAddColumnDialog()' style="color:#FFF")  
                            v-icon(size='15' ) icon-add 
                            span {{$t('GPT_KNOWLEDGE.ADD')}}
                    v-col(cols='10').d-flex.justify-end.pr-0
                        .search_bar_style(style='padding-top:2px;')
                            v-text-field(
                                v-model='search' 
                                flat 
                                dense  
                                append-icon='icon-search'
                                rounded
                                outlined
                                style='width:200px;'
                                hide-details
                            )
                        importExportBtn(:page='"Flexfield"' :search='search' :customerAttributeList='customerAttributeList' :currentAttributeId='currentAttributeId')
                .data-area.ellipsis(style='position: relative; ')
                    v-data-table.mt-2(:loading='loading' :search='search', :headers="imageAttributeHeader" , hide-default-footer="hide-default-footer", :items="imageAttributeTableData", height='96%' fixed-header :items-per-page='itemPerPage' :page.sync='page' @page-count='pageCount = $event')
                        template(v-for='e, index in imageAttributeHeader' v-slot:[`item.${e.value}`]='{ item }')
                            .ellipsis.t-black.text-center(:title='item[e.value]') {{ item[e.value] }}
                        template(v-slot:item.action='{ item }')
                            v-btn(icon='icon' @click='showEditTableDialog(item)')
                                v-icon(size='18') icon-edit
                            v-btn(icon='icon' @click='deleteTableItem(item.object_id)' :disabled="removeBtn_loading")
                                v-icon(size='18') icon-remove
                    v-pagination.py-1.mb-3( v-model='page' :length='pageCount' circle :total-visible="10") 
                //- add dialog
                v-dialog(v-model='addColumnDialog' max-width='500' content-class='statusDialog')
                    v-card
                        v-card-title.d-flex.justify-center {{$t('GPT_KNOWLEDGE.ADD')}}
                        v-card-text
                            v-form(v-model="valid" ref="form")
                                v-col(cols='12' md='12' v-for='(item,index) in dialogLabel' :key="index"  )
                                    div(v-if='item.flexfield_datatype=="Text"' :key='index')
                                        v-textarea(v-model='item.input_value'  
                                        :label= "item.name" 
                                        large='large' 
                                        persistent-hint
                                        rows="2" row-height="3"
                                        )
                                    div(v-if='item.flexfield_datatype=="Decimal"' :key='index')
                                        v-text-field(v-model.number='item.input_value' 
                                        :rules='formRule.numMandatoryRule' 
                                        :label= "item.name" 
                                        @input='item.input_value = check_num(item.input_value)')
                                    div(v-if='item.flexfield_datatype=="DateTime"' :key='index')
                                        v-menu(ref="menu" v-model="createMenu[parseInt(item.flexfield_column.slice(-2))]" :z-index='1000' :close-on-content-click="true" transition="scroll-y-transition" offset-y min-width="auto" ) 
                                            template(v-slot:activator="{ on, attrs }")
                                                v-text-field(v-model='item.input_value' :rules='formRule.dateRule' v-bind="attrs" v-on="on" :label= "item.name" hide-details="auto" )
                                            v-date-picker(v-model='item.input_value' @input='createMenu[parseInt(item.flexfield_column.slice(-2))] = false' no-title scrollable)
                                            //- date-picker(v-model='item.input_value' type="datetime" valueType='format' inline :showSecond='false' format='YYYY-MM-DD HH:mm' @change="datepickerChange" )                
                        v-card-actions
                            v-row
                                v-col.pr-2(cols="6")
                                    v-btn(
                                        :ripple="false",
                                        @click="onCancel('add')",
                                        width="100%",
                                        height="40",
                                        color="cancel",
                                        depressed,
                                        dark
                                    ) {{$t('GENERAL.CANCEL')}}
                                v-col.pl-2(cols="6")
                                    v-btn.btn_green(
                                        :ripple="false",
                                        @click="onConfirm",
                                        width="100%",
                                        height="40",
                                        color="#2ace87",
                                        depressed,
                                        dark,
                                    )
                                        v-progress-circular(v-if='addBtn_loading' indeterminate size='20')
                                        .t-white(v-else) {{$t('GENERAL.ADD')}}
                //- edit dialog
                v-dialog(v-model='editColumnDialog' max-width='500' content-class='statusDialog')
                    v-card
                        v-card-title.d-flex.justify-center {{$t('GPT_KNOWLEDGE.EDIT')}}
                        v-card-text
                            v-form(v-model="valid" ref="form")
                                v-col(cols='12' md='12' v-for='(item,index) in dialogLabel' :key="index" )
                                    div(v-if='item.flexfield_datatype=="Text"' :key='index')
                                        v-textarea(v-model='currentEditData[item.flexfield_column]'  
                                        :label= "item.name" 
                                        :name='item.name' 
                                        large='large' 
                                        persistent-hint
                                        rows="2" row-height="3"
                                        )
                                    div(v-if='item.flexfield_datatype=="Decimal"' :key='index')
                                        v-text-field(v-model.number='currentEditData[item.flexfield_column]' 
                                        :rules='formRule.numMandatoryRule' 
                                        :label= "item.name" 
                                        @input='item.input_value = check_num(item.input_value)')
                                    div(v-if='item.flexfield_datatype=="DateTime"' :key='index')
                                        v-menu(ref="menu" v-model="editMenu[parseInt(item.flexfield_column.slice(-2))]" :z-index='1000' :close-on-content-click="false" transition="scroll-y-transition" offset-y min-width="auto" ) 
                                            template(v-slot:activator="{ on, attrs }")
                                                v-text-field(v-model='currentEditData[item.flexfield_column]'  :rules='formRule.dateRule' v-bind="attrs" v-on="on" :label= "item.name" hide-details="auto" )
                                            v-date-picker(v-model='currentEditData[item.flexfield_column]' @input='editMenu[parseInt(item.flexfield_column.slice(-2))] = false' no-title scrollable)

                        v-card-actions
                            v-row
                                v-col.pr-2(cols="6")
                                    v-btn(
                                        :ripple="false",
                                        @click="onCancel('edit')",
                                        width="100%",
                                        height="40",
                                        color="cancel",
                                        depressed,
                                        dark
                                    ) {{$t('GENERAL.CANCEL')}}
                                v-col.pl-2(cols="6")
                                    v-btn.btn_green(
                                        :ripple="false",
                                        @click="onEdit()",
                                        width="100%",
                                        height="40",
                                        color="#2ace87",
                                        depressed,
                                        dark,
                                    )
                                        v-progress-circular(v-if='addBtn_loading' indeterminate size='20')
                                        .t-white(v-else) {{$t('GENERAL.CONFIRM')}}
                   
</template>

<script>
import AiBotsService from "@/services/AiBotsService";
import i18n from '/common/plugins/vue-i18n.js';
import importExportBtn from "@/components/Common/importExportBtn";

export default {
    props: {
        imageAttributeHeader:{
            type:Array,
            require:true
        },
        imageAttributeTableData:{
            type:Array,
            require:true
        },
        currentAttributeId:{
            type:Number,
            require:true
        },
        customerAttributeList:{
            type:Array,
            require:true
        },
        loading:{
            type:Boolean,
            require:true
        },
    },
    components: {
        importExportBtn,
    },
    data() {
        return {
            search:"",
            addBtn_loading:false,
            removeBtn_loading:false,
            dialogLabel: '',
            editedItem: {},
            addColumnDialog: false,
            editColumnDialog: false,
            openDatePicker:null,
            currentEditData: '',
            valid: false,
            editMenu: Array(30).fill(false),
            createMenu: Array(30).fill(false),
            formRule: {
                dateRule: [(v) => v == '' || /^\d{4}-\d{2}-\d{2}$|null/.test(v) || i18n.t('RULE.RULE_DATE')],
                fieldRules: [(v) => !!v || i18n.t('RULE.RULE_R')],
                nameRules: [(v) => !!v || i18n.t('RULE.RULE_R'),],
                numMandatoryRule: [(v) => (v == null || v == '' || /^\d+(\.\d+)?$/.test(v.toString())) || i18n.t('RULE.RULE_R_NUM_7')],
            },
            itemPerPage: 10,
            page: 1,
            pageCount: 1,
        }
    },
    async created(){
        await this.getDialogLabel();
    },
    methods: {
        validateForm() {
            if (this.$refs.form.validate()) 
                return true;
            else 
                return false;
        },
        check_num(num){
            if(num === "")
            {
                return null;
            }
            else
            {
                return num;
            }
        },
        datepickerFocus(item){
            this.openDatePicker = item;
        },
        async getDialogLabel(){
            let data = {'currentObjectTypeId': this.currentAttributeId};
            await AiBotsService.get_image_attribute_header(data).then(response => 
            {
                this.dialogLabel = response.data;
                this.dialogLabel.sort((a,b)=> a.name.localeCompare(b.name))
                this.dialogLabel.sort((a,b)=> a.name.length - b.name.length)
                this.dialogLabel.forEach(el => {
                    el.input_value = '';
                });
            })
        },
        showEditTableDialog(data){
            this.currentEditData = data;
            this.getDialogLabel();
            this.editColumnDialog = true;
        },
        showAddColumnDialog(){
            this.getDialogLabel();
            this.addColumnDialog = true;
        },
        async deleteTableItem(id){
            this.removeBtn_loading = true;
            await AiBotsService.delete_image_attribute_table(id).then((response)=>{
                if(response.data=='success'){
                    this.$emit('emitImageDataDialog', 'delete');
                    this.removeBtn_loading = false;
                }
            })
        },
        async onConfirm(){
            // this.formRule.dateRule.push((v) => /^\d{4}-\d{2}-\d{2}$|null/.test(v) && !!v || i18n.t('RULE.RULE_DATE'));
            this.dialogLabel.forEach(item=>{
                this.editedItem[item.flexfield_column] = item.input_value;
            })
            this.editedItem['object_type_id'] = this.currentAttributeId;

            for(let key in this.editedItem){
                if(this.editedItem[key] == ''){
                    this.editedItem[key] = null;
                }
            }
            if (this.validateForm() && !this.addBtn_loading) {
                this.addBtn_loading = true;
                await AiBotsService.add_image_attribute_table(this.editedItem).then(response =>{
                    if(response.data){
                        this.addColumnDialog = false;
                        this.addBtn_loading = false;
                        this.$emit("emitImageDataDialog");
                    }
                }).finally(()=>{
                    this.getDialogLabel();
                })
            }  
        },
        async onEdit(){
            if (this.validateForm() && !this.addBtn_loading) {
                this.addBtn_loading = true;
                for(let key in this.currentEditData){
                    if(this.currentEditData[key] == ''){
                        this.currentEditData[key] = null;
                    }
                }
                await AiBotsService.edit_image_attribute_table(this.currentEditData.object_id,this.currentEditData).then(() =>{
                    this.editColumnDialog = false;
                    this.addBtn_loading = false;
                    this.$emit("emitImageDataDialog");
                })
            }
        },
        onCancel(status){
            if(status == 'edit') this.editColumnDialog = false;
            if(status == 'add') this.addColumnDialog = false;
            this.$emit("emitImageDataDialog","cancel");
        }
    },
};
</script>